<template>
   <!-- TODO:
   -->
   <!--
      WHAT: The UI for collecting the Sendgrid's Email app configuration
      HISTORY:
      - V231207.2: Made toAddress's hint a variable as {{ created issue.
      - V231207.1: Consumed the actual appEndpoint from AWS Lambda function + Forced {{}} to be used for field names.
      - V231109.1: Handled consumerCode and appEndpoint as part of the inpout/output properties.
      - V230222.1: Added isActualEndpoint prop + Changed ## to {}.
      - V230221.1: 1st release.
   -->
<div>
   <v-tooltip left>
      <template v-slot:activator="{ on }">
         <v-btn text icon
            v-on="on"
            @click="btnClicked"
         >
            <v-icon>{{btnIconName}}</v-icon>
         </v-btn>
      </template>
      <span>{{btnTooltip}}</span>
   </v-tooltip>

   <v-dialog no-click-animation persistent scrollable
      max-width="1080px"
      v-model="dialog"
   >
      <v-card flat>
         <v-card-title class="title grey--text darken-4 font-weight-bold">Settings for {{appName}}
            <div class="flex-grow-1"></div>
            <v-btn v-if="!isActualEndpoint"
               color="primary"
               class="caption text-capitalize"
               @click="populateSettings"
            >Populate Settings</v-btn>
         </v-card-title>

         <v-card-text>
            <v-form lazy-validation
               ref="mainForm"
               v-model="isFormValid"
            >
               <v-row class="pt-2 mt-0">
                  <v-col xs="12" sm="12" :md="fromColSize">
                     <v-text-field dense persistent-hint
                        autocomplete="off"
                        ref="fromAddress"
                        label="* From Email Address"
                        hint="Specify a fixed email address or a rule group variable"
                        :rules="[rules.required, rules.fromAddress]"
                        v-model="formData.fromAddress"
                     ></v-text-field>
                  </v-col>
                  <v-col v-if="formData.fromAddress"
                     xs="12" sm="4" md="5"
                  >
                     <v-text-field clearable dense persistent-hint
                        autocomplete="off"
                        ref="fromName"
                        label="From Name"
                        hint="Use something recognizable. It can have rule group variables"
                        v-model="formData.fromName"
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-row v-if="formData.fromAddress" class="pt-3 mt-0">
                  <v-col xs="12" sm="12" md="7">
                     <v-text-field dense persistent-hint
                        autocomplete="off"
                        ref="toAddress"
                        label="* To Email(s)"
                        :hint="toAddressHint"
                        :rules="[rules.required, rules.toAddress]"
                        v-model="formData.toAddress"
                     ></v-text-field>
                  </v-col>
                  <v-col xs="12" sm="12" md="5">
                     <v-text-field dense persistent-hint
                        autocomplete="off"
                        ref="replyToAddress"
                        label="* Reply-to Email"
                        hint="A valid email address. Use the same domain as your From Address"
                        :rules="[rules.required, rules.toAddress]"
                        v-model="formData.replyToAddress"
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-row v-if="formData.fromAddress" class="pt-3 mt-0">
                  <v-col xs="12" sm="12" md="7">
                     <v-text-field dense persistent-hint
                        autocomplete="off"
                        ref="subject"
                        label="* Subject"
                        hint="Keep it relevant and non-spammy to avoid spam filters"
                        :rules="[rules.required]"
                        v-model="formData.subject"
                     ></v-text-field>
                  </v-col>
                  <v-col xs="12" sm="12" md="5">
                     <v-text-field dense persistent-hint
                        autocomplete="off"
                        ref="categories"
                        label="Categories"
                        hint="Separate categories with comma. Don't use Bulk or Trigger"
                        :rules="[rules.list]"
                        v-model="formData.categories"
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-row v-if="formData.fromAddress" class="pt-5 mt-0">
                  <v-col xs="12" sm="12" md="6">
                     <v-textarea outlined persistent-hint
                        autocomplete="off"
                        ref="htmlBody"
                        label="HTML Body"
                        hint=""
                        rows="4"
                        :rules="[rules.body(formData)]"
                        v-model="formData.htmlBody"
                     ></v-textarea>
                  </v-col>
                  <v-col xs="12" sm="12" md="6">
                     <v-textarea outlined persistent-hint
                        autocomplete="off"
                        ref="textBody"
                        label="Text Body"
                        hint="This plain-text email is displayed if recipients can't display your HTML email"
                        rows="4"
                        :rules="[rules.body(formData)]"
                        v-model="formData.textBody"
                     ></v-textarea>
                  </v-col>
               </v-row>
            </v-form>
         </v-card-text>

         <v-card-actions class="pt-0">
            <div class="flex-grow-1"></div>
            <v-btn text small
               class="px-0"
               color="blue darken-1"
               @click="cancelClicked"
            >Cancel</v-btn>
            <v-btn text small
               class="px-0 mx-0"
               color="blue darken-1"
               :disabled="!this.isFormValid"
               @click="saveClicked"
            >Save</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>

</div>
</template>

<script>
const NAME = "BtSendgridEmail";
const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// const FROM_PATTERN = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,9})|(##[^#]+##)$/;
// const FROM_PATTERN = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,9})|(\{[^{}]+\{)$/;
const FROM_PATTERN = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,9})|(\{[^{}]+\{)$/;
// const TO_PATTERN = /^((([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,9})|(##[^#]+##)),?\s*)+$/;
const TO_PATTERN = /^((([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,9})|(\{{[^{}]+\}})),?\s*)+$/;
const REPLYTO_PATTERN = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,9})$/;

class FormData {
   constructor(initVal) {
      this.fromAddress = initVal.fromAddress || '';
      this.fromName = initVal.fromName || '';
      this.toAddress = initVal.toAddress || '';
      this.replyToAddress = initVal.replyToAddress || '';
      this.subject = initVal.subject || '';
      this.htmlBody = initVal.htmlBody || '';
      this.textBody = initVal.textBody || '';
      this.categories = initVal.categories || '';
   }
}

export default {
   name: NAME,

   props: {
      value: {
         type: Object,
         default: () => {}
      },
      appName: {
         type: String,
         default: "Email - Sendgrid"
      },
      btnIconName: {
         type: String,
         default: "more_vert"
      },
      btnTooltip: {
         type: String,
         default: "click to configure"
      },
      debug: {
         type: Boolean,
         default: false
      },
      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         rules: {
            required: value => !!value || "Value is required!",
            email: value => !value || EMAIL_PATTERN.test(value) || "Invalid email address!",
            fromAddress: value => !value || FROM_PATTERN.test(value) || "Invalid email address or rule group variable!",
            toAddress: value => !value || TO_PATTERN.test(value) || "Invalid email address, variable, or rule group!",
            replyToAddress: value => !value || REPLYTO_PATTERN.test(value) || "Invalid email address!",
            list: value => {
               let result = true;
               if (value.trim()) {
                  value.split(',').forEach(val => {
                     const v = val.trim().toLowerCase();
                     if (!v || v === 'bulk' || v === 'trigger') {
                        result = false;
                        return;
                     }
                  });
               }
               return result || 'Value is invalid!';
            },
            body(fd) {
               if (fd.htmlBody) return true;
               else if (fd.textBody) return true;
               else return 'One of the HTML or text bodies should have value!';
            }
         },
         formData: {},
         dialog: false,
         isFormValid: false,
         toAddressHint: "A comma-separated list of fields and/or email addresses (It's usually {{email}})"
      }
   },

   computed: {
      fromColSize() {
         return this.formData.fromAddress ? 7 : 12;
      }
   },

   watch: {},

   methods: {
      log(msg, showAlert) {
         if (this.debug) {
            console.log(`-----${NAME} V231207.2 => ${msg}`);
            if (showAlert)
               alert(`-----${NAME} V231207.2 says:\n${msg}`);
         }
      },

      async btnClicked() {
         // alert(JSON.stringify(this.value))
         this.formData = new FormData(this.value.instruction || {});
         this.dialog = true;
         setTimeout(() => {
            this.$refs.fromAddress.focus();
         }, 10);
      },

      populateSettings() {
         this.formData = new FormData({
            "fromAddress": "from@mindfireinc.com",
            "fromName": "Behzad Talebpour",
            "toAddress": "{{email}}",
            "replyToAddress": "reply-to@mindfireinc.com",
            "subject": "a message for {{Name}}",
            "categories": "my category",
            "htmlBody": "<b>Hi {{firstname}} {{lastname}}!</b>",
            "textBody": "Hi {{firstname}} {{lastname}}!"
         });
      },

      saveClicked() {
         if (this.$refs.mainForm.validate()) {
            const settings = {
               fromAddress: this.formData.fromAddress,
               toAddress: this.formData.toAddress,
               replyToAddress: this.formData.replyToAddress,
               subject: this.formData.subject,
            };

            if (this.formData.fromName)
               settings.fromName = this.formData.fromName;
            if (this.formData.categories)
               settings.categories = this.formData.categories;
            if (this.formData.htmlBody)
               settings.htmlBody = this.formData.htmlBody;
            if (this.formData.textBody)
               settings.textBody = this.formData.textBody;
            
            const emitObj = {
               instruction: settings,
               consumerCode: 'bff557d9-2941-4ee2-ab07-866a22ff71dd',
               appEndpoint: ' '
            }

            this.$emit('change', emitObj);
            this.$emit('input', emitObj);
            this.cancelClicked();
         }
      },

      cancelClicked() {
         this.$refs.mainForm.resetValidation();
         this.dialog = false;
      }
   },

   created() {
      // this.log('in created()', true);
   },

   mounted() { }
}
</script>